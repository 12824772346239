import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faSlidersH,
  faQuestionCircle,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './app/App';

import './assets/styles/index.scss';

library.add(faCheck, faSlidersH, faQuestionCircle, faFilePdf, faFileWord);

if (process.env.REACT_APP_GTM_KEY) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
