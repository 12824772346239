// @ts-ignore
import React from 'react';

import { VFooter } from '@ventera-corporation/vstart-components-react';

import vstartLogo from '../../assets/images/logo_white.svg';

export function AppFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <VFooter
      logo={
        <img src={vstartLogo} alt="vstart logo" className="v-footer-logo" />
      }
      columns={[
        {
          title: 'Feedback',
          linkList: [
            {
              label: 'Contact Us',
              url: 'mailto:vstart@ventera.com',
              external: true,
            },
            {
              label: 'Submit Ideas',
              url: 'https://forms.gle/X6eaiHbHhCPWpoe96',
              external: true,
            },
          ],
        },
      ]}
      copyright={`${currentYear} Ventera Corporation`}
    />
  );
}
