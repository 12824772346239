// @ts-ignore
import React from 'react';

import { VButton } from '@ventera-corporation/vstart-components-react';

import * as hooks from 'api/mega-menu/hooks';

import { PAGE_PLAYBOOK } from 'app/site-map';

import { RelatedPlays } from 'components/related-plays';
import { Tags } from 'components/tags';

import { StrapiCollectionTypeAttributes } from 'types/strapi';

import './PlaybookMenu.scss';

export function PlaybookMenu() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data = {} } = hooks.usePlaybookMenu() as any;

  const { attributes: menu } = data?.data?.data || {};

  if (!menu) return null;

  const {
    intro,
    game_plans_content,
    topics_content,
    plays = {},
    game_plans = {},
    topics = {},
  } = menu;

  return (
    <div className="playbook-menu-wrapper" data-testid="playbook-menu">
      <div className="plays-wrapper">
        <div className="mb-4 px-4">
          <h2>{intro?.title}</h2>
          <p>{intro?.description}</p>
          <VButton href={PAGE_PLAYBOOK()}>Explore Playbook</VButton>
        </div>

        <div className="related-plays-wrapper mb-4 d-none d-sm-block px-4">
          <h3>Featured Plays</h3>
          <div className="d-none d-xl-block">
            <RelatedPlays
              related_plays={plays.data?.map(
                (p: StrapiCollectionTypeAttributes) => p?.attributes,
              )}
            />
          </div>
          <div className="d-none d-sm-block d-md-block d-xl-none">
            <RelatedPlays
              related_plays={plays.data
                ?.slice(0, 3)
                ?.map((p: StrapiCollectionTypeAttributes) => p?.attributes)}
            />
          </div>
        </div>
      </div>

      <div className="featured-tags px-4">
        <div className="gameplans-tags">
          <h2>{game_plans_content?.title}</h2>
          <p>{game_plans_content?.description}</p>
          <Tags
            tags={game_plans?.data?.map(
              (gp: StrapiCollectionTypeAttributes) => gp.attributes,
            )}
            title=""
            tagName="game_plans"
          />
        </div>

        <div className="topics-tags">
          <h2>{topics_content?.title}</h2>
          <p>{topics_content?.description}</p>
          <Tags
            tags={topics?.data?.map(
              (t: StrapiCollectionTypeAttributes) => t.attributes,
            )}
            title=""
            tagName="topics"
          />
        </div>
      </div>
    </div>
  );
}
