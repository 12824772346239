import { apiRequest } from '@ventera-corporation/vstart-components-react';

async function get(url: string) {
  const result = await apiRequest(url, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
    },
  });
  return result;
}

export const api = {
  get,
};
