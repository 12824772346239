// @ts-ignore
import React from 'react';

import { VButton } from '@ventera-corporation/vstart-components-react';

import * as T from 'types/strapi';

import './Tags.scss';

interface ITags {
  tags: T.StrapiCollectionType[];
  tagName: string;
  title: string;
}

export function Tags({ tags, title, tagName }: ITags) {
  return tags?.length ? (
    <div className="info-section tags-wrapper" data-testid="tags">
      {title && <h4>{title}</h4>}
      {tags.map((t: T.StrapiCollectionType, index: number) => (
        <VButton
          key={`${t.id}_${index}`}
          href={`/plays?tagName=${tagName}&tag=${encodeURIComponent(t.title)}`}
          variant="outline-primary"
          isPill={true}
        >
          {t.title}
        </VButton>
      ))}
    </div>
  ) : null;
}
