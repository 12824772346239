import { Suspense, lazy } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/images/logo.svg';
import { Navigate, Outlet, Route, Routes, useMatch } from 'react-router-dom';

import {
  VLoader,
  VHeader,
  VPageHeader,
  useUserMenu,
  VAuthenticatedRoute,
} from '@ventera-corporation/vstart-components-react';

import * as PAGES from 'app/site-map';

import PreAuthPage from 'modules/sign-in/pages/pre-auth/PreAuthPage';

import './AppRouter.scss';
import { PlaybookMenu } from './components/playbook-menu/PlaybookMenu';

library.add(faAngleUp, faAngleDown);

// LazyLoad some of these pages
const Design = lazy(() => import('modules/design/pages/Design'));
const MyProfile = lazy(() => import('modules/account/my-profile/MyProfile'));
const Home = lazy(() => import('modules/home/Home'));
const Play = lazy(() => import('modules/play/Play'));
const Playbook = lazy(() => import('modules/playbook/Playbook'));
const Plays = lazy(() => import('modules/plays/Plays'));
const Platform = lazy(() => import('modules/platform/pages/Platform'));
const Resources = lazy(
  () => import('modules/resources/pages/resources/Resources'),
);
const UnderConstruction = lazy(
  () =>
    import(
      'modules/under-construction/pages/under-construction/UnderConstruction'
    ),
);

function AppRouter() {
  const preAuthRouteMatch = useMatch(PAGES.PAGE_SIGN_IN());

  const { menuItems, hasAccess } = useUserMenu({
    openIcon: 'angle-up',
    closeIcon: 'angle-down',
  });

  const newMenu = [
    {
      label: 'Playbook',
      action: 'mega-menu',
      showItem: hasAccess({ require: 'authenticated' }),
      openIcon: 'angle-up',
      closeIcon: 'angle-down',
      children: <PlaybookMenu />,
    },
    {
      label: 'Design',
      action: PAGES.PAGE_DESIGN(),
      showItem: hasAccess({ require: 'authenticated' }),
    },
    {
      label: 'Platform',
      action: PAGES.PAGE_PLATFORM(),
      showItem: hasAccess({ require: 'authenticated' }),
    },
    {
      label: 'Frameworks',
      action: PAGES.PAGE_FRAMEWORKS(),
      showItem: hasAccess({ require: 'authenticated' }),
    },
    {
      label: 'Resources',
      action: PAGES.PAGE_RESOURCES(),
      showItem: hasAccess({ require: 'authenticated' }),
    },
  ];

  return (
    <main className="pb-app-body" data-testid="app-router">
      {!preAuthRouteMatch && (
        <header>
          <VHeader
            position="static"
            navLinks={[...newMenu, ...menuItems]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            logo={(<img src={logo} alt="vStart" />) as any}
          />
          <VPageHeader homeLinkText="Home" />
        </header>
      )}
      <Suspense fallback={<VLoader />}>
        <Routes>
          <Route
            element={
              <VAuthenticatedRoute>
                <Outlet />
              </VAuthenticatedRoute>
            }
          >
            <Route path="*" element={<Navigate to={PAGES.PAGE_HOME()} />} />
            <Route path={PAGES.PAGE_HOME()} element={<Home />} />
            <Route path={PAGES.PAGE_PLAYBOOK()} element={<Playbook />} />
            <Route path={PAGES.PAGE_PLAYS()} element={<Plays />} />
            <Route path={PAGES.PAGE_PLAY()} element={<Play />} />
            <Route path={PAGES.PAGE_MY_PROFILE()} element={<MyProfile />} />
            <Route path={PAGES.PAGE_RESOURCES()} element={<Resources />} />
            <Route path={PAGES.PAGE_DESIGN()} element={<Design />} />
            <Route path={PAGES.PAGE_PLATFORM()} element={<Platform />} />
            <Route
              path={PAGES.PAGE_FRAMEWORKS()}
              element={<UnderConstruction />}
            />
          </Route>
          <Route path={PAGES.PAGE_SIGN_IN()} element={<PreAuthPage />} />
        </Routes>
      </Suspense>
    </main>
  );
}

export default AppRouter;
