// @ts-ignore
import React from 'react';

import { VButton } from '@ventera-corporation/vstart-components-react';

import StrapiImage from 'components/strapi-image/StrapiImage';

import * as T from 'modules/play/play.types';

import './RelatedPlays.scss';

interface IPlayDetails {
  related_plays: T.Play[] | T.PlayListItem[];
}

export function RelatedPlays({ related_plays }: IPlayDetails) {
  const content = related_plays?.map((play: T.Play | T.PlayListItem) => {
    // TODO: need to figure out how to organize shapes since strapi sends back 2 shapes!
    // @ts-ignore
    const url = play.main_image?.data?.attributes?.url || play.main_image?.url;
    return (
      <div className="info-section-wrapper" key={`play_${play.id}`}>
        <div className="info-section">
          <StrapiImage src={url} />
          <VButton variant="link" href={`/plays/${play.slug}`}>
            {play.play_title}
          </VButton>
        </div>
      </div>
    );
  });

  return content?.length ? (
    <div className="related-plays-section" data-testid="related-plays">
      {content}
    </div>
  ) : null;
}
