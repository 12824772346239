import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  VSkipNav,
  AuthenticationContextProvider,
  EnvContextProvider,
  VErrorBoundary,
  configureApiRequest,
} from '@ventera-corporation/vstart-components-react';

import { AppFooter } from './app-footer/AppFooter';
import AppRouter from './app-router/AppRouter';
import './App.scss';
import { getStore } from './redux/store';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};
const queryClient = new QueryClient(queryClientConfig);
const store = getStore();

configureApiRequest(store.dispatch);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <VErrorBoundary>
          <EnvContextProvider envVars={process.env}>
            <AuthenticationContextProvider>
              <VSkipNav>
                <QueryClientProvider client={queryClient}>
                  <AppRouter />
                  <AppFooter />
                </QueryClientProvider>
              </VSkipNav>
            </AuthenticationContextProvider>
          </EnvContextProvider>
        </VErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
