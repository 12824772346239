import quoteIcon from 'assets/images/quoteIcon.svg';
import './Testimonial.scss';

interface ITestimonial {
  quote: string;
  author: string;
}

const Testimonial = (props: ITestimonial) => {
  const { quote, author } = props;
  return (
    <div className="testimonials-carousel-item">
      <div className="testimonials-quote">
        <img src={quoteIcon} alt="quote" className="quote-icon" />
        <p className="quote-text">{quote}</p>
        <span className="quote-author">{author}</span>
      </div>
    </div>
  );
};

export default Testimonial;
