// @ts-ignore
import React from 'react';

import { API_STRAPI } from 'api/constants';

interface IStrapiImage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function StrapiImage(props: IStrapiImage) {
  const { src, ...rest } = props;
  if (!src) return null;

  if (process.env.NODE_ENV === 'development') {
    return (
      <img
        alt=""
        data-testid="local-image"
        src={`${API_STRAPI}${src}`}
        {...rest}
      />
    );
  }

  return <img alt="" data-testid="prod-image" src={src} {...rest} />;
}
