export const PRE_AUTH_DEFAULTS = {
  INTRODUCTION_TAGLINE: 'Accelerated Delivery. Proven Results.',
  INTRODUCTION_DESCRIPTION: `vSTART is the Ventera Scaffolding, Templates, Artifacts, and
                  Release Toolkit, built from Ventera's collective experience,
                  ingenuity, and thought leadership. Discover the technology
                  platform, playbooks, design artifacts, and more.`,
  PRODUCT_TAGLINE:
    'vSTART has resources and solutions for the entire product lifecycle.',
  PRODUCT_INFO_ITEMS: [
    {
      title: 'Playbook',
      description: `A dynamic resource containing processes, workflows, strategies, techniques, and values that make up Ventera's go-to approach for getting things done.`,
    },
    {
      title: 'Design',
      description: `Pre-made UX templates,  accessible design kits based on USWDS principles, foundational design systems (future) that can help designers and researchers go from problem to solutions.  `,
    },
    {
      title: 'Platform',
      description: `Technology platforms composed of code scaffolding, interoperable components, plug-and-play CI/CD pipeline, and data pipeline supporting AI and analytics to help projects get started quickly.`,
    },
  ],
  TESTIMONIALS: [
    {
      quote: `Probably shaved a solid 40+ hours of work at least… at least setting up initial UI components [for a form]. It’s a large amount… we shouldn’t ever have to recreate input components or how we do routing / forms unless we want to change them.`,
      author: 'Front End Developer',
    },
    {
      quote: `Probably shaved a solid 40+ hours of work at least… at least setting up initial UI components [for a form]. It’s a large amount… we shouldn’t ever have to recreate input components or how we do routing / forms unless we want to change them.`,
      author: 'Front End Developer',
    },
  ],
  PRINCIPLES_HEADER: 'Ventera Delivery Principles',
  PRINCIPLES_DESCRIPTION: `vSTART embodies the key Ventera Delivery Principles and enables teams to consistently create and deliver best-in-class services and products for customers.`,
  PRINCIPLES: [
    { principle: 'Learn-by-Doing' },
    { principle: 'Continuous Improvement, Innovation' },
    { principle: 'User-Centered Agile Development' },
    { principle: 'Default to Open' },
    { principle: 'Automate and Streamline' },
    { principle: 'Autonomous Teams, Share Responsibility' },
    { principle: 'Transparency and Collaboration' },
    { principle: 'Measurable, Sustainable Business Value' },
    { principle: 'DevSecOps Culture and Automation' },
  ],
};

export const FIVE_MINUTES = 300000;
export const THIRTY_SECONDS = 30000;
