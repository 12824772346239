/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

import introduction_image from 'assets/images/introduction_hero.png';
import introduction_mobile_image from 'assets/images/introduction_hero_mobile.png';
import logo from 'assets/images/logo.svg';
import principles_image from 'assets/images/principles.png';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  VButton,
  VCarousel,
  VLoader,
  usePageUpdate,
  useVSkipNav,
  useAuthentication,
  cognitoUrl,
  SIGN_IN,
  useEnvVars,
} from '@ventera-corporation/vstart-components-react';

import { getPage } from 'api/pages/routes';

import { PAGE_SIGN_IN, PAGE_HOME } from 'app/site-map';

import StrapiImage from 'components/strapi-image/StrapiImage';

import Testimonial from './components/testimonial/Testimonial';
import { FIVE_MINUTES, PRE_AUTH_DEFAULTS, THIRTY_SECONDS } from './constants';

import './PreAuthPage.scss';

function PreAuthPage() {
  const envVars = useEnvVars();
  useVSkipNav('sign-up-header');
  usePageUpdate({
    documentTitle: PAGE_SIGN_IN.title,
    header: {},
  });
  const [pageData, setPageData] = useState<any>();
  const [carouselTimer, setCarouselTimer] = useState<any>(FIVE_MINUTES);
  const { isLoading, user } = useAuthentication();
  const navigate = useNavigate();
  const location: any = useLocation();

  const { from } = location.state || {
    from: { pathname: PAGE_HOME() },
  };

  const isBottom = (el: HTMLElement | null) => {
    if (el !== null) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
  };

  const trackScrolling = useCallback(() => {
    const carousel: HTMLElement | null =
      document.getElementById('carousel-wrapper');

    if (isBottom(carousel)) {
      setCarouselTimer(THIRTY_SECONDS);
      document.removeEventListener('scroll', trackScrolling);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  useEffect(() => {
    if (user && !isLoading) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, location.state, from, isLoading]);

  useEffect(() => {
    async function getPageData() {
      try {
        const response: any = (await getPage({
          pagePath: 'pre-auth-page',
        })) as any;
        if (!response.data) {
          throw Error(response);
        }
        setPageData(response.data?.data?.attributes);
      } catch (error) {
        console.log('No page data available. Error: ', error);
      }
    }
    getPageData();
  }, []);

  if (isLoading) {
    return <VLoader />;
  }

  const INTRO_SECTION_TAGLINE =
    pageData?.intro_section?.tagline || PRE_AUTH_DEFAULTS.INTRODUCTION_TAGLINE;
  const INTRO_SECTION_DESCRIPTION =
    pageData?.intro_section?.description ||
    PRE_AUTH_DEFAULTS.INTRODUCTION_DESCRIPTION;
  const PRODUCT_INFO_SECTION_TAGLINE =
    pageData?.product_info_section?.tagline ||
    PRE_AUTH_DEFAULTS.PRODUCT_TAGLINE;
  const PRODUCT_INFO_SECTION_PRODUCTS: any[] = pageData?.product_info_section
    ?.product_info_items
    ? pageData.product_info_section.product_info_items
    : PRE_AUTH_DEFAULTS.PRODUCT_INFO_ITEMS;
  const TESTIMONIALS: any[] = pageData?.testimonials_section?.length
    ? pageData.testimonials_section
    : PRE_AUTH_DEFAULTS.TESTIMONIALS;
  const PRINCIPLES_SECTION_HEADER = pageData?.principles_section?.header
    ? pageData.principles_section.header
    : PRE_AUTH_DEFAULTS.PRINCIPLES_HEADER;
  const PRINCIPLES_SECTION_DESCRIPTION = pageData?.principles_section
    ?.description
    ? pageData.principles_section.description
    : PRE_AUTH_DEFAULTS.PRINCIPLES_DESCRIPTION;
  const PRINCIPLES_SECTION_PRINCIPLES: any[] = pageData?.principles_section
    ?.principles
    ? pageData.principles_section.principles
    : PRE_AUTH_DEFAULTS.PRINCIPLES;

  return (
    <div className="pb-pre-auth" data-testid="sign-in-page">
      <section className="pb-pre-auth__introduction">
        <Container>
          <Row>
            <Col sm={6} className="introduction-content">
              <img src={logo} alt="vSTART" />
              <h1 id="sign-up-header" data-testid="pre-auth-header">
                {INTRO_SECTION_TAGLINE}
              </h1>
              <p>{INTRO_SECTION_DESCRIPTION}</p>
              <VButton
                className="external-sign-in-button"
                href={cognitoUrl(envVars, from.pathname)}
                data-testid="external-cognito-sign-in"
              >
                {SIGN_IN}
              </VButton>
            </Col>
            <Col sm={6}>
              <div className="d-sm-none">
                <img
                  className="pb-pre-auth__hero-image"
                  src={introduction_mobile_image}
                  alt="Hero"
                />
              </div>
              <div className="d-none d-sm-block">
                <img
                  className="pb-pre-auth__hero-image"
                  src={introduction_image}
                  alt="Hero"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-pre-auth__product-line">
        <Container className="product-line-container">
          <h2 className="product-line-title">{PRODUCT_INFO_SECTION_TAGLINE}</h2>
          <Row>
            {PRODUCT_INFO_SECTION_PRODUCTS.map((product: any) => (
              <Col key={product.title} md={4} className="product-line-col">
                <Row className="product-line-item">
                  <Col md={3} lg={2}>
                    {product.icon?.data?.attributes?.url && (
                      <StrapiImage
                        src={product.icon?.data?.attributes?.url}
                        alt="hexagon"
                      />
                    )}
                  </Col>
                  <Col
                    sm={{ span: 10 }}
                    md={{ span: 8, offset: 1 }}
                    lg={{ span: 9, offset: 1 }}
                    xxl={{ span: 9, offset: 0 }}
                    className="product-line-item-content"
                  >
                    <h3>{product.title}</h3>
                    <p>{product.description}</p>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="pb-pre-auth__testimonial">
        <Container id="carousel-wrapper" data-testid="v-carousel">
          <VCarousel interval={carouselTimer}>
            {TESTIMONIALS.map((testimonial: any, index: number) => (
              <Testimonial
                quote={testimonial.quote}
                author={testimonial.author}
                key={`testimonial-${index}`}
              />
            ))}
          </VCarousel>
        </Container>
      </section>
      <section className="pb-pre-auth__principles">
        <Container>
          <Row>
            <Col sm={6}>
              <h2>{PRINCIPLES_SECTION_HEADER}</h2>
              <p>{PRINCIPLES_SECTION_DESCRIPTION}</p>
              <ul>
                {PRINCIPLES_SECTION_PRINCIPLES.map(({ principle }: any) => (
                  <li key={principle}>{principle}</li>
                ))}
              </ul>
            </Col>
            <Col sm={6}>
              <img src={principles_image} alt="principles" />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default PreAuthPage;
