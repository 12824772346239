import * as pathToRegex from 'path-to-regexp';

export const TITLE_PREFIX = 'vStart - ';

// Fn to create a route path and page title
function createPath([path, title]: string[]) {
  const reverse = pathToRegex.compile(path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function method(params?: any, match?: any): string {
    if (params) {
      return reverse({ ...params, ...match });
    }

    return path;
  }
  method.title = TITLE_PREFIX + title;

  return method;
}

// General pages (Home, sign in, profile, etc.)
export const PAGE_ROOT = createPath(['/', 'Home']);
export const PAGE_HOME = createPath(['/home', 'Home']);
export const PAGE_REGISTRATION = createPath([
  '/registration',
  'User Registration',
]);
export const PAGE_SIGN_IN = createPath(['/sign-in', 'Sign In']);
export const PAGE_SIGN_IN_CONFIRMATION = createPath([
  '/sign-in-confirmation',
  'Sign In Confirmation',
]);
export const PAGE_MY_PROFILE = createPath(['/my-profile', 'My Profile']);
export const PAGE_MY_PROFILE_EDIT = createPath([
  '/my-profile/edit',
  'Edit My Profile',
]);
export const PAGE_UNDER_CONSTRUCTION = createPath([
  '/under-construction',
  'Under Construction',
]);

// Error pages
export const PAGE_404_ERROR = createPath(['/404', 'Page Not Found']);
export const PAGE_500_ERROR = createPath(['/500', 'Page Error']);

// Plays pages
export const PAGE_PLAYS = createPath(['/plays', 'Plays']);
export const PAGE_PLAY = createPath(['/plays/:id', 'Play']);

// Playbook pages
export const PAGE_PLAYBOOK = createPath(['/playbook', 'Playbook']);
export const PAGE_UX_TEMPLATES = createPath(['/ux-templates', 'UX Templates']);
export const PAGE_DESIGN_KITS = createPath(['/design-kits', 'Design Kits']);
export const PAGE_GETTING_STARTED = createPath([
  '/getting-started',
  'Getting Started',
]);

// Design
export const PAGE_DESIGN = createPath(['/design', 'Design']);

// Platform
export const PAGE_PLATFORM = createPath(['/platform', 'Platform']);

// Frameworks
export const PAGE_FRAMEWORKS = createPath(['/frameworks', 'Frameworks']);

// Resources pages
export const PAGE_RESOURCES = createPath(['/resources', 'Resources']);

// Other
export const PAGE_HELP = createPath(['/help', 'Help']);
export const PAGE_GLOSSARY = createPath(['/glossary', 'Glossary']);
